<template>
  <div>
    <div class="header">基本信息</div>
    <!-- <ul class="content" v-if="enterpriseInfo">
      <li>
        <span>企业名称</span>
        <div><a-input class="ipt" v-model="enterpriseInfo.enterpriseName" placeholder='请输入企业名称'></a-input></div>
      </li>
      <li>
        <span>行业类型</span>
        <div>
          <a-select class="select" placeholder="请选择" v-model="enterpriseInfo.enterpriseIndustry">
            <a-select-option v-for="(item,index) in industryLists" :value="item.id" :key="index">{{item.industryName}}</a-select-option>
          </a-select>
        </div>
      </li>
      <li>
        <span>企业地址</span>
        <div class="last-line">
          <a-cascader class="area" v-model="address" :options="options" placeholder="请选择" @change="onChange" />
        </div>
      </li>
      <li>
        <span>详细地址</span>
        <div>
          <a-input class="ipt" v-model="enterpriseInfo.enterpriseAddress" placeholder='请输入详细地址'></a-input>
        </div>
      </li>
      <li>
        <span>企业联系人</span>
        <div><a-input class="ipt" v-model="enterpriseInfo.enterpriseContacts" placeholder='请输入企业联系人'></a-input></div>
      </li>
      <li>
        <span>企业联系电话</span>
        <div><a-input class="ipt" v-model="enterpriseInfo.enterprisePhone" placeholder='请输入企业联系电话'></a-input></div>
      </li>
      <li>
        <span>纳税人类型</span>
        <div>
          <a-select class="select" placeholder="请选择" v-model="enterpriseInfo.taxpayerType">
            <a-select-option v-for="item in taxpayerType" :value="item.value" :key="item.value">{{item.label}}</a-select-option>
          </a-select>
        </div>
      </li>
      <li>
        <span>业务员</span>
        <div>
          <a-select class="select" placeholder="请选择业务员" 
            showSearch
            :defaultActiveFirstOption="false"
            :filterOption="false"
            @search="handleSearchYW"
            @change="handleChangeYW"
            v-model="enterpriseInfo.sysUserId"
          >
            <a-select-option v-for="item in salesmanLists" :value="item.userId" :key="item.userId">{{item.realName}} {{item.phoneNo}}</a-select-option>
          </a-select>
        </div>
      </li>
    </ul> -->
    <div class="nav">基本信息</div>
    <ul class="content" v-if="enterpriseInfo">
      <li>
        <span>企业名称</span>
        <div>{{enterpriseInfo.enterpriseName}}</div>
      </li>
      <li>
        <span>行业类型</span>
        <div>{{enterpriseInfo.enterpriseIndustryText}}</div>
      </li>
      <li>
        <span>企业地址</span>
        <div>{{enterpriseInfo.detailedAddress}}</div>
      </li>
      <li>
        <span>详细地址</span>
        <div>{{enterpriseInfo.enterpriseAddress}}</div>
      </li>
      <li>
        <span>企业联系人</span>
        <div>{{enterpriseInfo.enterpriseContacts}}</div>
      </li>
      <li>
        <span>企业联系电话</span>
        <div>{{enterpriseInfo.enterprisePhone}}</div>
      </li>
      <li style="width:100%">
        <span>纳税人类型</span>
        <div>{{enterpriseInfo.taxpayerType==='102401'?'一般纳税人':'小规模纳税人'}}</div>
      </li>
    </ul>
    <!-- <div class="nav">客户经理信息</div>
    <ul class="content" v-if="kefuInfo">
      <li>
        <span>真实姓名</span>
        <div>{{kefuInfo.realName}}</div>
      </li>
      <li>
        <span>电话号码</span>
        <div>{{kefuInfo.phoneNo}}</div>
      </li>
    </ul> -->
  </div>
</template>
<script>
import {
  getSysRegion,
  enterpriseBaseInfo,
  getSysIndustry,
  getEmployee,
  updateEnterpriseBaseInfo,
  getLoginInfo
} from '@/api/api';
import { USER_INFO } from "@/store/mutation-types";
import Vue from 'vue';
export default {
  data () {
    return {
      baseInfo: {
        gm: undefined
      },
      options: [],
      userInfo: null,
      enterpriseInfo: null,
      industryLists: [],
      taxpayerType: [{
        value: '102401',
        label: '一般纳税人'
      }, {
        value: '102411',
        label: '小规模纳税人'
      }],
      address: [],
      salesmanLists: [],
      kefuInfo: null
    }
  },
  created () {
    this.userInfo = Vue.ls.get(USER_INFO);
    this.getSysRegion();
    this.enterpriseBaseInfo();
    this.getIndustry();
  },
  methods: {
    // 保存
    save () {
      if (this.enterpriseInfo.enterpriseName===''){
        this.$message.error('企业名称不能为空')
        return
      }
      if (this.enterpriseInfo.enterpriseAddress===''){
        this.$message.error('详细地址不能为空')
        return
      }
      if (this.enterpriseInfo.enterpriseContacts===''){
        this.$message.error('联系人不能为空')
        return
      }
      if (this.enterpriseInfo.enterprisePhone===''){
        this.$message.error('联系电话不能为空')
        return
      }
      if (this.address.length===0){
        this.$message.error('企业地址不能为空')
        return
      }
      this.enterpriseInfo.enterpriseProvince = this.address[0]
      this.enterpriseInfo.enterpriseCity = this.address[1]
      this.enterpriseInfo.enterpriseArea = this.address[2]
      updateEnterpriseBaseInfo(this.enterpriseInfo).then(res => {
        if (res.success) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 取消
    cancel () {
      this.enterpriseBaseInfo();
    },
    // 查询业务员
    handleSearchYW(value) {
      if (value) {
        getEmployee({name: value}).then(res => {
          if (res.success) {
            this.salesmanLists = res.result
            this.salesmanLists.forEach(item => {
              item.userId = item.userId + ''
            })
          }
        })
      }
    },
    handleChangeYW(value) {
    },
    // 获取基本信息
    enterpriseBaseInfo () {
      getLoginInfo().then(res => {
        if (res.success) {
          this.userInfo = res.result;
          enterpriseBaseInfo(this.userInfo.enterpriseId).then(res => {
          if (res.success) {
            this.enterpriseInfo = res.result
            this.address = [this.enterpriseInfo.enterpriseProvince+'',this.enterpriseInfo.enterpriseCity+'',this.enterpriseInfo.enterpriseArea+'']
            getEmployee({name: this.enterpriseInfo.sysUserName}).then(res => {
              if (res.success) {
                this.salesmanLists = res.result
                this.salesmanLists.forEach(item => {
                  item.userId = item.userId + ''
                  if (item.userId===this.enterpriseInfo.sysUserId){
                    this.kefuInfo = item
                  }
                })
              }
            })
          }
        })
        }
      })
    },
    // 获取行业
    getIndustry () {
      getSysIndustry().then(res => {
        if (res.success) {
          this.industryLists = res.result
          this.industryLists.forEach(item => {
            item.id = item.id + ''
          })
        }
      })
    },
    // 获取地区省份
    getSysRegion () {
      getSysRegion().then(res => {
        if (res.success) {
          this.options = res.result
        }
      })
    },
    // 选择地区
    onChange(value) {
    },
  }
}
</script>
<style scoped lang="less">
.header{
  color: #283039;
  font-size: 18px;
  margin: 0 0 38px 0;
  font-weight: bold;
}
.content{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  li{
    display: flex;
    width: 50%;
    box-sizing: border-box;
    align-items: center;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    font-size: 12px;
    color: #727682;
    span{
      width: 140px;
      height: 54px;
      background: #FAFAFA;
      padding: 20px 18px;
    }
    div{
      padding: 20px 18px;
      height: 54px;
      position: relative;
      .ipt{
        width: 80%;
        position: absolute;
        top: 12px;
        left: 20px;
      }
      .select{
        width:86%;
        position: absolute;
        top: -9px;
        left: 2px;
      }
      .date{
        position: absolute;
        top: -9px;
        left: 2px;
        width: 86%;
        height: 68px;
        background:rgba(0,0,0,0);
      }
    }
    .last-line{
      display: flex;
      align-items: center;
      position: relative;
      .area{
        position: absolute;
        top: -8px;
        left: 0px;
        width: 86%;
        background: rgba(0,0,0,0);
        height: 74px;
      }
      .iptt{
        width: 30%;
        position: absolute;
        top: 12px;
        right: 50px;
      }
    }
  }
}
.nav{
  color: #283039;
  font-size: 14px;
  margin: 30px 0 18px 0;
}
.opt{
  text-align: center;
  margin: 30px 0 0 0;
}
</style>
<style>
.content .ant-calendar-picker-clear, .ant-calendar-picker-icon{
  right: 25px;
}
.content .ant-cascader-picker-arrow,.content .ant-cascader-picker-clear{
  right: 30px;
}
.content .ant-cascader-picker-label{
  left: 20px;
}
</style>
